<template>
  <div>
    <MineList :way="2"></MineList>
  </div>
</template>

<script>
import MineList from '@/components/list/mine.vue'
export default {
  components: { MineList },
  data() {
    return {}
  },

  mounted() {},

  methods: {}
}
</script>

<style lang="less" scoped></style>
