<template>
  <div>
    <div class="vertical_title flex">{{ way == 1 ? '我的赛事' : '我的活动' }}</div>
    <div class="nav flex">
      <div
        class="nav_item"
        :class="{ navActive: index == params.state }"
        v-for="(item, index) in navList"
        :key="index"
        @click="navClick(index)"
      >
        {{ item }}
      </div>
    </div>
    <div class="list flex_direction_column">
      <div class="list_item flex_space_between" v-for="(item, index) in list" :key="index" @click="itemClick(item)">
        <img v-if="item.type == 2 && way == 1" src="@/assets/image/img/15.png" class="online" />
        <div class="left">
          <img :src="item.cover" />
        </div>
        <div class="center flex_direction_column">
          <span>订单编号：{{ item.out_trade_no }}</span>
          <span class="one_line">{{ item.active_name }}</span>
          <!-- 赛事 -->
          <div class="desc flex_direction_column" v-if="way == 1">
            <p>参赛组别：{{ item.group_name }}</p>
            <p>参赛时间：{{ item.start_time }}</p>
          </div>
          <!-- 活动 -->
          <div class="desc flex_direction_column" v-if="way == 2">
            <p>活动地点：{{ item.province }}·{{ item.city }}</p>
            <p>活动时间：{{ item.start_time }}</p>
          </div>
          <span>¥{{ item.total_price }}</span>
        </div>
        <div class="right flex_direction_column">
          <span>{{ item.create_time }}</span>
          <span :style="orderTextStyle(item)">{{ orderText(item) }}</span>
          <div class="btn flex" v-if="item.show_state == 0">
            <!-- <el-popconfirm v-if="item.show_state == 0" title="确定删除订单吗？" @confirm="confirm(item)">
              <span @click.stop slot="reference">取消订单</span>
            </el-popconfirm> -->
            <span @click.stop="cancle(item)">取消订单</span>
            <span>去支付</span>
          </div>
        </div>
      </div>
    </div>
    <Lack
      v-if="list.length == 0"
      imgWidth="115px"
      imgHeight="76px"
      text="暂无数据"
      :imgSrc="require('@/assets/image/img/12.png')"
    ></Lack>
    <div class="pagination">
      <el-pagination
        v-if="list.length != 0"
        background
        :page-size="15"
        :total="total"
        layout="prev, pager, next"
        @current-change="currentChange"
      >
      </el-pagination>
    </div>

    <deletePopup :orderId="orderId" :isShow="isShow" @confirm="confirm" @close="close"></deletePopup>
  </div>
</template>

<script>
import statusMinxin from '@/minxin/status.js'
import Lack from '@/components/lack/lack.vue'
import deletePopup from '@/components/popup/delete.vue'
import { activeOrder, matchOrder, orderCancel } from '@/service/api/user'
export default {
  mixins: [statusMinxin],
  components: { Lack, deletePopup },
  props: ['way'],
  data() {
    return {
      navList: ['全部', '待支付', '已报名', '已完成', '已退款'],
      params: {
        state: 0,
        page: 1
      },
      total: 0,
      isShow: false,
      list: [],
      orderId: 0
    }
  },

  mounted() {},

  methods: {
    // 我的赛事
    async matchOrder() {
      const res = await matchOrder(this.params)
      this.list = res.msg.data
      this.total = res.msg.total
    },
    // 我的活动
    async activeOrder() {
      const res = await activeOrder(this.params)
      this.list = res.msg.data
      this.total = res.msg.total
      console.log(res)
    },
    itemClick(item) {
      if (this.way == 1) {
        this.$router.push({
          path: '/match-entry',
          query: { orderId: item.id }
        })
      } else {
        this.$router.push({
          path: '/activity-entry',
          query: {
            orderId: item.id
          }
        })
      }
    },
    navClick(index) {
      this.params.state = index
      this.wayFun(this.way)
    },
    currentChange(page) {
      this.params.page = page
      this.wayFun(this.way)
    },
    wayFun(way) {
      if (way == 1) {
        // 我的赛事
        this.matchOrder()
      } else {
        // 我的活动
        this.activeOrder()
      }
    },
    async confirm(id) {
      const res = await orderCancel({ id })
      this.isShow = false
      if (res.code == 1) {
        this.$message('取消成功')
        this.wayFun(this.way)
      }
    },
    close() {
      this.isShow = false
    },
    cancle(item) {
      this.orderId = item.id
      this.isShow = true
    }
  },
  watch: {
    way: {
      handler(val) {
        this.params.state = 0
        this.params.page = 1
        this.wayFun(val)
      },
      immediate: true,
      deep: true
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .el-pager li {
  font-weight: normal;
}
/deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: @color_one;
  color: #fff;
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 29px;
}
.nav {
  margin-top: 24px;
  .navActive {
    font-weight: bold;
    color: @color_one !important;
    border-bottom: 1px solid @color_one !important;
  }
  .nav_item {
    cursor: pointer;
    line-height: 25px;
    color: @color_six;
    margin-right: 38px;
    padding-bottom: 12px;
    font-size: @font_size_18;
    border-bottom: 1px solid #fff;
  }
}
.list {
  .list_item {
    cursor: pointer;
    position: relative;
    border-radius: 3px;

    margin-bottom: 20px;
    align-items: flex-start;
    border: 1px solid #eeeeee;
    padding: 21px 21px 19px 21px;
    .online {
      left: 0;
      top: 13px;
      width: 72px;
      height: 28px;
      position: absolute;
    }
    &:nth-child(1) {
      margin-top: 21px;
    }
    .left {
      width: 192px;
      height: 130px;
      border-radius: 5px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 5px;
      }
    }
    .center {
      flex: 1;
      margin-left: 15px;
      align-items: flex-start;
      span {
        &:nth-child(1) {
          height: 20px;
          line-height: 20px;
          color: @color_eight;
          font-size: @font_size_14;
        }
        &:nth-child(2) {
          height: 25px;
          margin-top: 4px;
          line-height: 25px;
          font-size: @font_size_18;
        }
        &:nth-child(4) {
          height: 25px;
          margin-top: 13px;
          line-height: 25px;
          color: @color_one;
          font-size: @font_size_18;
        }
      }
      .desc {
        margin-top: 2px;
        font-weight: 400;
        line-height: 22px;
        color: @color_eight;
        font-size: @font_size_14;
      }
    }
    .right {
      width: 155px;
      align-items: flex-end;
      justify-content: flex-end;
      span {
        &:nth-child(1) {
          height: 20px;
          line-height: 20px;
          color: @color_eight;
          font-size: @font_size_14;
        }
        &:nth-child(2) {
          height: 22px;
          width: 100%;
          text-align: right;
          margin-top: 26px;
          color: #c59053;
          line-height: 22px;
          font-size: @font_size_16;
        }
      }
      .btn {
        margin-top: 20px;
        span {
          width: 100px;
          height: 32px !important;
          color: #fff;
          line-height: 32px !important;
          text-align: center;
          border-radius: 3px;
          font-size: @font_size_14;
          &:nth-child(1) {
            margin-top: 0;
            background: #4fbebd;
            margin-right: 10px;
          }
          &:nth-child(2) {
            margin-top: 0;
            background: @color_one;
          }
        }
      }
    }
  }
}
</style>
